import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  permissionsStream: BehaviorSubject<any[]> = new BehaviorSubject([]);

  constructor(private httpClient: HttpClient, private router: Router) {}

  init(component): boolean {
    const permissions = window.localStorage.getItem('permissions');
    var flag = false;
    for (let i = 0; i < JSON.parse(permissions).length; i++) {
      if (
        JSON.parse(permissions)[i].permission.code == component.data.permission
      ) {
        flag = true;
      }
    }
    if (!flag) {
      this.router.navigate(['/permission']);
    }
    return flag;
  }
}
