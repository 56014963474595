import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { from, Observable, of } from 'rxjs';
import { catchError, concatMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AccountService } from './_services/account.service';
import { UtilsService } from './_services/utils.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  o = { q: Math.random() };
  userDataSource: {
    role: any;
  } = {
    role: null,
  };
  isLoggedInStream: Observable<boolean>;
  permissionsStream: Observable<any[]>;
  permissions = JSON.parse(window.localStorage.getItem('permissions'));
  navigationFlag = false;
  reportDesignFlag = false;
  reportFlag = false;
  systemFlag = false;
  productFlag = false;
  settingsFlag = false;
  constructor(
    public router: Router,
    private accountService: AccountService,
    private utilsService: UtilsService,
    private httpClient: HttpClient
  ) {}

  ngOnInit(): void {
    if (window.localStorage.getItem('user')) {
      this.getUser();
    }
    this.isLoggedInStream = this.accountService.isLoggedInStream.asObservable();
    this.permissionsStream =
      this.accountService.permissionsStream.asObservable();

    this.permissions = this.permissionsStream.subscribe((response) => {
      this.permissions = response;
      if (
        this.permissions.filter(
          (v) =>
            v.permission.code.toUpperCase().includes('DASHBOARD.MENU') ||
            v.permission.code.toUpperCase().includes('STOCKIN.MENU') ||
            v.permission.code.toUpperCase().includes('STOCKOUT.MENU') ||
            v.permission.code.toUpperCase().includes('CLAIM.MENU') ||
            v.permission.code.toUpperCase().includes('INSPECTION.MENU')
        ).length != 0
      ) {
        this.navigationFlag = true;
      } else {
        this.navigationFlag = false;
      }

      if (
        this.permissions.filter(
          (v) =>
            v.permission.code.toUpperCase().includes('DESIGN_REPORT.MENU') ||
            v.permission.code.toUpperCase().includes('DESIGN_DASHBOARD.MENU') ||
            v.permission.code.toUpperCase().includes('DESIGN_REPORT.LISTING')
        ).length != 0
      ) {
        this.reportDesignFlag = true;
      } else {
        this.reportDesignFlag = false;
      }

      if (
        this.permissions.filter(
          (v) =>
            v.permission.code
              .toUpperCase()
              .includes('STOCKIN.REPORT.DETAIL.VIEW') ||
            v.permission.code
              .toUpperCase()
              .includes('STOCKOUT.REPORT.DETAIL.VIEW') ||
            v.permission.code
              .toUpperCase()
              .includes('WARRANTY.REPORT.DETAIL.VIEW') ||
            v.permission.code.toUpperCase().includes('CLAIM.REPORT.DETAIL.VIEW')
        ).length != 0
      ) {
        this.reportFlag = true;
      } else {
        this.reportFlag = false;
      }

      // if (
      //   this.permissions.filter((v) =>
      //     v.permission.code.toUpperCase().includes('PRODUCT.MENU')
      //   ).length != 0
      // ) {
      //   this.productFlag = true;
      // } else {
      //   this.productFlag = false;
      // }

      if (
        this.permissions.filter(
          (v) =>
            v.permission.code.toUpperCase().includes('PRODUCT.MENU') ||
            v.permission.code.toUpperCase().includes('CHANNEL.MENU') ||
            v.permission.code.toUpperCase().includes('APPARATUS.MENU')
        ).length != 0
      ) {
        this.settingsFlag = true;
      } else {
        this.settingsFlag = false;
      }

      if (
        this.permissions.filter(
          (v) =>
            v.permission.code.toUpperCase().includes('USERS.MENU') ||
            v.permission.code.toUpperCase().includes('ROLES.MENU') ||
            v.permission.code.toUpperCase().includes('ROLE_PERMISSION.MENU')
        ).length != 0
      ) {
        this.systemFlag = true;
      } else {
        this.systemFlag = false;
      }
    });
    this.accountService.init().subscribe();
  }
  getUser(): void {
    this.httpClient
      .post<any>(`${environment.baseApiUrl}/users/getQuery`, null, {
        params: {
          join: 'role',
          filter: 'username||$eq||' + window.localStorage.getItem('user'),
        },
      })
      .pipe(
        concatMap((res) => {
          if (!res.success) {
            return this.utilsService.showAlert(res.message, res.errors);
          }

          this.userDataSource = res.data.result[0];
          return this.getPermission();
        }),
        catchError((err) => {
          const defaultMessage =
            'Unable to get user. Maybe connectivity issue?';

          const message =
            err instanceof HttpErrorResponse
              ? defaultMessage
              : typeof err === 'object'
              ? err.message
              : err ?? defaultMessage;

          return this.utilsService.showAlert(message);
        })
      )
      .subscribe();
  }
  getPermission(): Observable<boolean | void> {
    return this.httpClient
      .post<any>(`${environment.baseApiUrl}/rolePermissions/getQuery`, null, {
        params: {
          join: 'permission,role',
          filter: 'role.name||$eq||' + this.userDataSource.role?.name,
        },
      })
      .pipe(
        concatMap((res) => {
          if (!res.success) {
            return this.utilsService.showAlert(res.message, res.errors);
          }

          window.localStorage.setItem(
            'permissions',
            JSON.stringify(res.data.result)
          );
          const permissions = window.localStorage.getItem('permissions');

          if (permissions) {
            this.accountService.permissionsStream.asObservable();
          }
          return of(null);
        }),
        catchError((err) => {
          const defaultMessage =
            'Unable to get permission. Maybe connectivity issue?';

          const message =
            err instanceof HttpErrorResponse
              ? defaultMessage
              : typeof err === 'object'
              ? err.message
              : err ?? defaultMessage;

          return this.utilsService.showAlert(message);
        })
      );
  }
  logout() {
    this.accountService
      .logout()
      .pipe(
        concatMap(() => {
          return from(this.goToLogin());
        })
      )
      .subscribe();
  }

  step = 0;

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  async goToLogin() {
    return this.router.navigate(['/login']);
  }
  async go() {
    return this.router.navigate(['/view-report2', 'stockin']);
  }
}
