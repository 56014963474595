import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './_guards/auth.guard';
import { PermissionGuard } from './_guards/permission.guard';
import { ReverseAuthGuard } from './_guards/reverse-auth.guard';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () =>
      import('./home/home.module').then((m) => m.HomePageModule),
    canLoad: [AuthGuard],
  },
  // {
  //   path: '',
  //   redirectTo: 'home',
  //   pathMatch: 'full',
  // },

  {
    path: 'login',
    loadChildren: () =>
      import('./login/login.module').then((m) => m.LoginPageModule),
    canLoad: [ReverseAuthGuard],
  },
  {
    path: 'reset-password',
    loadChildren: () =>
      import('./reset-password/reset-password.module').then(
        (m) => m.ResetPasswordPageModule
      ),
  },
  {
    path: 'stockin',
    loadChildren: () =>
      import('./stockin/stockin.module').then((m) => m.StockinPageModule),
    canLoad: [PermissionGuard],
    data: { permission: 'STOCKIN.LISTING' },
  },
  {
    path: 'add-stockin',
    loadChildren: () =>
      import('./add-stockin/add-stockin.module').then(
        (m) => m.AddStockinPageModule
      ),
    canLoad: [PermissionGuard],
    data: { permission: 'STOCKIN.VIEW' },
  },
  {
    path: 'stockout',
    loadChildren: () =>
      import('./stockout/stockout.module').then((m) => m.StockoutPageModule),
    canLoad: [PermissionGuard],
    data: { permission: 'STOCKOUT.LISTING' },
  },
  {
    path: 'add-stockout',
    loadChildren: () =>
      import('./add-stockout/add-stockout.module').then(
        (m) => m.AddStockoutPageModule
      ),
    canLoad: [PermissionGuard],
    data: { permission: 'STOCKOUT.VIEW' },
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./dashboard/dashboard.module').then((m) => m.DashboardPageModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'warranty-activation',
    loadChildren: () =>
      import('./warranty-activation/warranty-activation.module').then(
        (m) => m.WarrantyActivationPageModule
      ),
    canLoad: [PermissionGuard],
    data: { permission: 'WARRANTY.LISTING' },
  },
  {
    path: 'claims',
    loadChildren: () =>
      import('./claims/claims.module').then((m) => m.ClaimsPageModule),
    canLoad: [PermissionGuard],
    data: { permission: 'CLAIM.LISTING' },
  },
  {
    path: 'product-manage',
    loadChildren: () =>
      import('./product-manage/product-manage.module').then(
        (m) => m.ProductManagePageModule
      ),
    canLoad: [PermissionGuard],
    data: { permission: 'PRODUCT.LISTING' },
  },
  {
    path: 'add-product',
    loadChildren: () =>
      import('./add-product/add-product.module').then(
        (m) => m.AddProductPageModule
      ),
    canLoad: [PermissionGuard],
    data: { permission: 'PRODUCT.VIEW' },
  },
  {
    path: 'serial-number-manage',
    loadChildren: () =>
      import('./serial-number-manage/serial-number-manage.module').then(
        (m) => m.SerialNumberManagePageModule
      ),
    canLoad: [AuthGuard],
  },
  {
    path: 'generate-serial-number',
    loadChildren: () =>
      import('./generate-serial-number/generate-serial-number.module').then(
        (m) => m.GenerateSerialNumberPageModule
      ),
    canLoad: [AuthGuard],
  },
  {
    path: 'assign-serial-number',
    loadChildren: () =>
      import('./assign-serial-number/assign-serial-number.module').then(
        (m) => m.AssignSerialNumberPageModule
      ),
    canLoad: [AuthGuard],
  },
  {
    path: 'edit-warranty',
    loadChildren: () =>
      import('./edit-warranty/edit-warranty.module').then(
        (m) => m.EditWarrantyPageModule
      ),
    canLoad: [PermissionGuard],
    data: { permission: 'WARRANTY.UPDATE' },
  },
  {
    path: 'add-claims',
    loadChildren: () =>
      import('./add-claims/add-claims.module').then(
        (m) => m.AddClaimsPageModule
      ),
    canLoad: [PermissionGuard],
    data: { permission: 'CLAIM.CREATE' },
  },
  {
    path: 'edit-claims',
    loadChildren: () =>
      import('./edit-claims/edit-claims.module').then(
        (m) => m.EditClaimsPageModule
      ),
    canLoad: [PermissionGuard],
    data: { permission: 'CLAIM.UPDATE' },
  },
  {
    path: 'channel-manage',
    loadChildren: () =>
      import('./channel-manage/channel-manage.module').then(
        (m) => m.ChannelManagePageModule
      ),
    canLoad: [PermissionGuard],
    data: { permission: 'CHANNEL.LISTING' },
  },
  {
    path: 'add-channel',
    loadChildren: () =>
      import('./add-channel/add-channel.module').then(
        (m) => m.AddChannelPageModule
      ),
    canLoad: [PermissionGuard],
    data: { permission: 'CHANNEL.VIEW' },
  },
  {
    path: 'role-management',
    loadChildren: () =>
      import('./role-management/role-management.module').then(
        (m) => m.RoleManagementPageModule
      ),
    canLoad: [PermissionGuard],
    data: { permission: 'ROLES.LISTING' },
  },
  {
    path: 'add-role',
    loadChildren: () =>
      import('./add-role/add-role.module').then((m) => m.AddRolePageModule),
    canLoad: [PermissionGuard],
    data: { permission: 'ROLES.VIEW' },
  },
  {
    path: 'user-manage',
    loadChildren: () =>
      import('./user-manage/user-manage.module').then(
        (m) => m.UserManagePageModule
      ),
    canLoad: [PermissionGuard],
    data: { permission: 'USERS.LISTING' },
  },
  {
    path: 'add-user',
    loadChildren: () =>
      import('./add-user/add-user.module').then((m) => m.AddUserPageModule),
    canLoad: [PermissionGuard],
    data: { permission: 'USERS.VIEW' },
  },
  {
    path: 'permission-settings',
    loadChildren: () =>
      import('./permission-settings/permission-settings.module').then(
        (m) => m.PermissionSettingsPageModule
      ),
    canLoad: [PermissionGuard],
    data: { permission: 'ROLE_PERMISSION.LISTING' },
  },
  {
    path: 'inspection-form',
    loadChildren: () =>
      import('./inspection-form/inspection-form.module').then(
        (m) => m.InspectionFormPageModule
      ),
    canLoad: [PermissionGuard],
    data: { permission: 'INSPECTION.LISTING' },
  },
  {
    path: 'add-inspection-form',
    loadChildren: () =>
      import('./add-inspection-form/add-inspection-form.module').then(
        (m) => m.AddInspectionFormPageModule
      ),
    canLoad: [PermissionGuard],
    data: { permission: 'INSPECTION.VIEW' },
  },
  {
    path: 'apparatus',
    loadChildren: () =>
      import('./apparatus/apparatus.module').then((m) => m.ApparatusPageModule),
    canLoad: [PermissionGuard],
    data: { permission: 'APPARATUS.LISTING' },
  },
  {
    path: 'add-apparatus',
    loadChildren: () =>
      import('./add-apparatus/add-apparatus.module').then(
        (m) => m.AddApparatusPageModule
      ),
    canLoad: [PermissionGuard],
    data: { permission: 'APPARATUS.VIEW' },
  },
  {
    path: 'report-management',
    loadChildren: () =>
      import('./report-management/report-management.module').then(
        (m) => m.ReportManagementPageModule
      ),
    // canLoad: [PermissionGuard],
    //data: { permission: 'DESIGN_REPORT.LISTING' },
  },
  {
    path: 'edit-report',
    loadChildren: () =>
      import('./edit-report/edit-report.module').then(
        (m) => m.EditReportPageModule
      ),
    canLoad: [PermissionGuard],
    data: { permission: 'DESIGN_REPORT.UPDATE' },
  },
  {
    path: 'add-dashboard',
    loadChildren: () =>
      import('./add-dashboard/add-dashboard.module').then(
        (m) => m.AddDashboardPageModule
      ),
    canLoad: [PermissionGuard],
    data: { permission: 'DESIGN_DASHBOARD.CREATE' },
  },
  {
    path: 'stockin-report',
    loadChildren: () =>
      import('./stockin-report/stockin-report.module').then(
        (m) => m.StockinReportPageModule
      ),
    canLoad: [PermissionGuard],
    data: { permission: 'STOCKIN.REPORT.DETAIL.VIEW' },
  },
  {
    path: 'view-report',
    loadChildren: () =>
      import('./view-report/view-report.module').then(
        (m) => m.ViewReportPageModule
      ),
    canLoad: [PermissionGuard],
    data: { permission: 'DESIGN_REPORT.VIEW' },
  },
  {
    path: 'view-report2',
    loadChildren: () =>
      import('./view-report2/view-report2.module').then(
        (m) => m.ViewReport2PageModule
      ),
    canLoad: [PermissionGuard],
    data: { permission: 'DESIGN_REPORT.VIEW' },
  },
  {
    path: 'permission',
    loadChildren: () =>
      import('./permission/permission.module').then(
        (m) => m.PermissionPageModule
      ),
    canLoad: [AuthGuard],
  },
  {
    path: 'report-list',
    loadChildren: () =>
      import('./report-list/report-list.module').then(
        (m) => m.ReportListPageModule
      ),
    canLoad: [PermissionGuard],
    data: { permission: 'DESIGN_REPORT.LISTING' },
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        ...routes,
        // These 2 must be defined at the very bottom of this list
        {
          path: '',
          redirectTo: 'login',
          pathMatch: 'full',
        },
        {
          path: '**',
          redirectTo: 'login',
        },
      ],
      { preloadingStrategy: PreloadAllModules }
    ),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
