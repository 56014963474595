import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Route,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree
} from '@angular/router';
import { NavController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { AccountService } from '../_services/account.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private navController: NavController,
    private accountService: AccountService
  ) {}

  private validate(route: Route | ActivatedRouteSnapshot): boolean {
    // TODO: Technically we need to use the tokens to login
    if (this.accountService.accessToken) {
      // Logged in, allow to activate home
      return true;
    }

    // Not logged in, redirect to login
    this.navController.navigateBack(['/login'], {
      replaceUrl: true,
    });

    return true;
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.validate(route);
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.validate(route);
  }
}
