<ion-app>
  <ion-split-pane contentId="main">
    <ion-menu
      contentId="main"
      [disabled]="!(isLoggedInStream | async)"
      *ngIf="
        !(
          router.url.includes('edit-report') ||
          router.url.includes('view-report2')
        )
      "
    >
      <ion-header>
        <ion-toolbar>
          <ion-title>Menu</ion-title>
        </ion-toolbar>
      </ion-header>

      <ion-content>
        <div id="container">
          <div class="tabcontent">
            <mat-card>
              <mat-list role="list">
                <mat-accordion class="example-headers-align" multi>
                  <mat-expansion-panel
                    [expanded]="step === 0"
                    (opened)="setStep(0)"
                    hideToggle
                    *ngIf="navigationFlag == true"
                  >
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        <mat-list-item role="listitem" class="menutitle">
                          Navigation</mat-list-item
                        >
                      </mat-panel-title>
                    </mat-expansion-panel-header>

                    <ng-container
                      *ngFor="let item of permissionsStream | async"
                    >
                      <mat-list-item
                        *ngIf="item.permission.code == 'DASHBOARD.MENU'"
                        role="listitem"
                        routerLink="/dashboard"
                        class="menuitem"
                        routerLinkActive="active-link"
                        [routerLinkActiveOptions]="{ exact: true }"
                        ><mat-icon
                          aria-hidden="false"
                          aria-label="Example home icon"
                          class="menuicon"
                          >dashboard</mat-icon
                        >

                        Dashboard
                      </mat-list-item>
                    </ng-container>

                    <ng-container
                      *ngFor="let item of permissionsStream | async"
                    >
                      <mat-list-item
                        *ngIf="item.permission.code == 'STOCKIN.MENU'"
                        role="listitem"
                        routerLink="/stockin"
                        class="menuitem"
                        routerLinkActive="active-link"
                        [routerLinkActiveOptions]="{ exact: true }"
                        ><mat-icon
                          aria-hidden="false"
                          aria-label="Example home icon"
                          class="menuicon"
                          >call_missed</mat-icon
                        >
                        Stock In
                      </mat-list-item>
                    </ng-container>

                    <ng-container
                      *ngFor="let item of permissionsStream | async"
                    >
                      <mat-list-item
                        *ngIf="item.permission.code == 'STOCKOUT.MENU'"
                        role="listitem"
                        routerLink="/stockout"
                        class="menuitem"
                        routerLinkActive="active-link"
                        [routerLinkActiveOptions]="{ exact: true }"
                        ><mat-icon
                          aria-hidden="false"
                          aria-label="Example home icon"
                          class="menuicon"
                          >call_missed_outgoing</mat-icon
                        >
                        Stock Out
                      </mat-list-item>
                    </ng-container>

                    <ng-container
                      *ngFor="let item of permissionsStream | async"
                    >
                      <mat-list-item
                        *ngIf="item.permission.code == 'WARRANTY.MENU'"
                        role="listitem"
                        routerLink="/warranty-activation"
                        class="menuitem"
                        routerLinkActive="active-link"
                        [routerLinkActiveOptions]="{ exact: true }"
                        ><mat-icon
                          aria-hidden="false"
                          aria-label="Example home icon"
                          class="menuicon"
                          >card_giftcard</mat-icon
                        >
                        Warranty
                      </mat-list-item>
                    </ng-container>

                    <ng-container
                      *ngFor="let item of permissionsStream | async"
                    >
                      <mat-list-item
                        *ngIf="item.permission.code == 'CLAIM.MENU'"
                        role="listitem"
                        routerLink="/claims"
                        class="menuitem"
                        routerLinkActive="active-link"
                        [routerLinkActiveOptions]="{ exact: true }"
                        ><mat-icon
                          aria-hidden="false"
                          aria-label="Example home icon"
                          class="menuicon"
                          >notifications_none</mat-icon
                        >
                        Claim
                      </mat-list-item>
                    </ng-container>

                    <ng-container
                      *ngFor="let item of permissionsStream | async"
                    >
                      <mat-list-item
                        *ngIf="item.permission.code == 'INSPECTION.MENU'"
                        role="listitem"
                        routerLink="/inspection-form"
                        class="menuitem"
                        routerLinkActive="active-link"
                        [routerLinkActiveOptions]="{ exact: true }"
                        ><mat-icon
                          aria-hidden="false"
                          aria-label="Example home icon"
                          class="menuicon"
                          >note</mat-icon
                        >
                        Inspection
                      </mat-list-item>
                    </ng-container>
                  </mat-expansion-panel>

                  <mat-expansion-panel
                    [expanded]="step === 1"
                    (opened)="setStep(1)"
                    hideToggle
                    *ngIf="reportFlag == true"
                  >
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        <mat-list-item role="listitem" class="menutitle">
                          Report</mat-list-item
                        >
                      </mat-panel-title>
                    </mat-expansion-panel-header>

                    <ng-container
                      *ngFor="let item of permissionsStream | async"
                    >
                      <mat-list-item
                        *ngIf="
                          item.permission.code == 'STOCKIN.REPORT.DETAIL.VIEW'
                        "
                        role="listitem"
                        routerLink="/view-report/stockin"
                        class="menuitem"
                        routerLinkActive="active-link"
                        [routerLinkActiveOptions]="{ exact: true }"
                        ><mat-icon
                          aria-hidden="false"
                          aria-label="Example home icon"
                          class="menuicon"
                          >insert_drive_file</mat-icon
                        >
                        Stock In Listing
                      </mat-list-item>
                    </ng-container>
                    <!--/report-management/stockout-->
                    <ng-container
                      *ngFor="let item of permissionsStream | async"
                    >
                      <mat-list-item
                        *ngIf="
                          item.permission.code == 'STOCKOUT.REPORT.DETAIL.VIEW'
                        "
                        role="listitem"
                        routerLink="/view-report/stockout"
                        class="menuitem"
                        routerLinkActive="active-link"
                        [routerLinkActiveOptions]="{ exact: true }"
                        ><mat-icon
                          aria-hidden="false"
                          aria-label="Example home icon"
                          class="menuicon"
                          >insert_drive_file</mat-icon
                        >
                        Stock Out Listing
                      </mat-list-item>
                    </ng-container>

                    <ng-container
                      *ngFor="let item of permissionsStream | async"
                    >
                      <mat-list-item
                        *ngIf="
                          item.permission.code == 'WARRANTY.REPORT.DETAIL.VIEW'
                        "
                        role="listitem"
                        routerLink="/view-report/warranty"
                        class="menuitem"
                        routerLinkActive="active-link"
                        [routerLinkActiveOptions]="{ exact: true }"
                        ><mat-icon
                          aria-hidden="false"
                          aria-label="Example home icon"
                          class="menuicon"
                          >insert_drive_file</mat-icon
                        >
                        Warranty Registrations
                      </mat-list-item>
                    </ng-container>

                    <ng-container
                      *ngFor="let item of permissionsStream | async"
                    >
                      <mat-list-item
                        *ngIf="
                          item.permission.code == 'CLAIM.REPORT.DETAIL.VIEW'
                        "
                        role="listitem"
                        routerLink="/view-report/claim"
                        class="menuitem"
                        routerLinkActive="active-link"
                        [routerLinkActiveOptions]="{ exact: true }"
                        ><mat-icon
                          aria-hidden="false"
                          aria-label="Example home icon"
                          class="menuicon"
                          >insert_drive_file</mat-icon
                        >
                        Claims
                      </mat-list-item>
                    </ng-container>
                    <ng-container
                      *ngFor="let item of permissionsStream | async"
                    >
                      <mat-list-item
                        *ngIf="
                          item.permission.code == 'CLAIM.REPORT.DETAIL.VIEW'
                        "
                        role="listitem"
                        routerLink="/view-report/claimReason"
                        class="menuitem"
                        routerLinkActive="active-link"
                        [routerLinkActiveOptions]="{ exact: true }"
                        ><mat-icon
                          aria-hidden="false"
                          aria-label="Example home icon"
                          class="menuicon"
                          >insert_drive_file</mat-icon
                        >
                        Claim Problem
                      </mat-list-item>
                    </ng-container>

                    <ng-container
                      *ngFor="let item of permissionsStream | async"
                    >
                      <mat-list-item
                        *ngIf="
                          item.permission.code == 'WARRANTY.REPORT.DETAIL.VIEW'
                        "
                        role="listitem"
                        routerLink="/view-report/warrantyReminder"
                        class="menuitem"
                        routerLinkActive="active-link"
                        [routerLinkActiveOptions]="{ exact: true }"
                        ><mat-icon
                          aria-hidden="false"
                          aria-label="Example home icon"
                          class="menuicon"
                          >insert_drive_file</mat-icon
                        >
                        Warranty Validity
                      </mat-list-item>
                    </ng-container>

                    <ng-container
                      *ngFor="let item of permissionsStream | async"
                    >
                      <mat-list-item
                        *ngIf="
                          item.permission.code == 'WARRANTY.REPORT.DETAIL.VIEW'
                        "
                        role="listitem"
                        routerLink="/view-report/customerMonthlyDateGiftVoucher"
                        class="menuitem"
                        routerLinkActive="active-link"
                        [routerLinkActiveOptions]="{ exact: true }"
                        ><mat-icon
                          aria-hidden="false"
                          aria-label="Example home icon"
                          class="menuicon"
                          >insert_drive_file</mat-icon
                        >
                        Customer Birthday
                      </mat-list-item>
                    </ng-container>
                  </mat-expansion-panel>

                  <!-- <mat-expansion-panel
                    [expanded]="step === 2"
                    (opened)="setStep(2)"
                    hideToggle
                    *ngIf="productFlag == true"
                  >
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        <mat-list-item role="listitem" class="menutitle">
                          Product</mat-list-item
                        >
                      </mat-panel-title>
                    </mat-expansion-panel-header>

                    <ng-container
                      *ngFor="let item of permissionsStream | async"
                    >
                      <mat-list-item
                        *ngIf="item.permission.code == 'PRODUCT.MENU'"
                        role="listitem"
                        routerLink="/product-manage"
                        class="menuitem"
                        routerLinkActive="active-link"
                        [routerLinkActiveOptions]="{ exact: true }"
                        ><mat-icon
                          aria-hidden="false"
                          aria-label="Example home icon"
                          class="menuicon"
                          >stars</mat-icon
                        >
                        Product
                      </mat-list-item>
                    </ng-container>
                  </mat-expansion-panel> -->

                  <mat-expansion-panel
                    [expanded]="step === 2"
                    (opened)="setStep(2)"
                    hideToggle
                    *ngIf="settingsFlag == true"
                  >
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        <mat-list-item role="listitem" class="menutitle">
                          Settings</mat-list-item
                        >
                      </mat-panel-title>
                    </mat-expansion-panel-header>

                    <ng-container
                    *ngFor="let item of permissionsStream | async"
                  >
                    <mat-list-item
                      *ngIf="item.permission.code == 'PRODUCT.MENU'"
                      role="listitem"
                      routerLink="/product-manage"
                      class="menuitem"
                      routerLinkActive="active-link"
                      [routerLinkActiveOptions]="{ exact: true }"
                      ><mat-icon
                        aria-hidden="false"
                        aria-label="Example home icon"
                        class="menuicon"
                        >stars</mat-icon
                      >
                      Product
                    </mat-list-item>
                  </ng-container>

                    <ng-container
                      *ngFor="let item of permissionsStream | async"
                    >
                      <mat-list-item
                        *ngIf="item.permission.code == 'CHANNEL.MENU'"
                        role="listitem"
                        routerLink="/channel-manage"
                        class="menuitem"
                        routerLinkActive="active-link"
                        [routerLinkActiveOptions]="{ exact: true }"
                        ><mat-icon
                          aria-hidden="false"
                          aria-label="Example home icon"
                          class="menuicon"
                          >settings_ethernet</mat-icon
                        >
                        Channel
                      </mat-list-item>
                    </ng-container>

                    <ng-container
                      *ngFor="let item of permissionsStream | async"
                    >
                      <mat-list-item
                        *ngIf="item.permission.code == 'APPARATUS.MENU'"
                        role="listitem"
                        routerLink="/apparatus"
                        class="menuitem"
                        routerLinkActive="active-link"
                        [routerLinkActiveOptions]="{ exact: true }"
                        ><mat-icon
                          aria-hidden="false"
                          aria-label="Example home icon"
                          class="menuicon"
                          >scatter_plot</mat-icon
                        >
                        Apparatus</mat-list-item
                      >
                    </ng-container>
                  </mat-expansion-panel>

                  <mat-expansion-panel
                    [expanded]="step === 3"
                    (opened)="setStep(3)"
                    hideToggle
                    *ngIf="systemFlag == true"
                  >
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        <mat-list-item role="listitem" class="menutitle">
                          System</mat-list-item
                        >
                      </mat-panel-title>
                    </mat-expansion-panel-header>

                    <ng-container
                      *ngFor="let item of permissionsStream | async"
                    >
                      <mat-list-item
                        *ngIf="item.permission.code == 'USERS.MENU'"
                        role="listitem"
                        routerLink="/user-manage"
                        class="menuitem"
                        routerLinkActive="active-link"
                        [routerLinkActiveOptions]="{ exact: true }"
                        ><mat-icon
                          aria-hidden="false"
                          aria-label="Example home icon"
                          class="menuicon"
                          >supervised_user_circle</mat-icon
                        >
                        Users
                      </mat-list-item>
                    </ng-container>

                    <ng-container
                      *ngFor="let item of permissionsStream | async"
                    >
                      <mat-list-item
                        *ngIf="item.permission.code == 'ROLES.MENU'"
                        role="listitem"
                        routerLink="/role-management"
                        class="menuitem"
                        routerLinkActive="active-link"
                        [routerLinkActiveOptions]="{ exact: true }"
                        ><mat-icon
                          aria-hidden="false"
                          aria-label="Example home icon"
                          class="menuicon"
                          >verified_user</mat-icon
                        >
                        Roles
                      </mat-list-item>
                    </ng-container>

                    <ng-container
                      *ngFor="let item of permissionsStream | async"
                    >
                      <mat-list-item
                        *ngIf="item.permission.code == 'ROLE_PERMISSION.MENU'"
                        role="listitem"
                        routerLink="/permission-settings"
                        class="menuitem"
                        routerLinkActive="active-link"
                        [routerLinkActiveOptions]="{ exact: true }"
                        ><mat-icon
                          aria-hidden="false"
                          aria-label="Example home icon"
                          class="menuicon"
                          >flag</mat-icon
                        >
                        Role Permissions
                      </mat-list-item>
                    </ng-container>
                    <ng-container
                      *ngFor="let item of permissionsStream | async"
                    >
                      <mat-list-item
                        *ngIf="item.permission.code == 'DESIGN_REPORT.MENU'"
                        role="listitem"
                        routerLink="/report-list"
                        class="menuitem"
                        routerLinkActive="active-link"
                        [routerLinkActiveOptions]="{ exact: true }"
                        ><mat-icon
                          aria-hidden="false"
                          aria-label="Example home icon"
                          class="menuicon"
                          >insert_drive_file</mat-icon
                        >
                        Report Design
                      </mat-list-item>
                    </ng-container>
                  </mat-expansion-panel>
                </mat-accordion>

                <!--<mat-list-item
                  role="listitem"
                  routerLink="/serial-number-manage"
                  class="menuitem"
                  ><mat-icon
                    aria-hidden="false"
                    aria-label="Example home icon"
                    class="menuicon"
                    >code</mat-icon
                  >
                  Serial History
                </mat-list-item>-->

                <!-- <mat-list-item role="listitem" class="menutitle">
                  Login Access</mat-list-item
                >
                <mat-list-item role="listitem" routerLink="/login" class="menuitem"
                  ><mat-icon
                    aria-hidden="false"
                    aria-label="Example home icon"
                    class="menuicon"
                    >lock</mat-icon
                  >
                  Login
                </mat-list-item>
                <mat-list-item
                  role="listitem"
                  routerLink="/reset-password"
                  class="menuitem"
                  ><mat-icon
                    aria-hidden="false"
                    aria-label="Example home icon"
                    class="menuicon"
                    >keyboard</mat-icon
                  >
                  Reset Password
                </mat-list-item> -->
              </mat-list>
            </mat-card>
          </div>
        </div>
      </ion-content>
    </ion-menu>

    <ion-router-outlet id="main"></ion-router-outlet>
  </ion-split-pane>
</ion-app>
