import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlertController, ToastController } from '@ionic/angular';
import { from, Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  prompt: HTMLIonAlertElement;
  constructor(
    private httpClient: HttpClient,
    private toastController: ToastController,
    private alertController: AlertController
  ) {}

  showToast(message: string) {
    return from(
      this.toastController.create({
        message: message,
        duration: 3500,
        buttons: [
          {
            side: 'end',
            icon: 'close',
            role: 'cancel',
          },
        ],
      })
    ).pipe(concatMap((toast) => toast.present()));
  }

  showAlert(message: string, errors: any[] = []) {
    return from(
      this.alertController.create({
        header: 'Information',
        subHeader: message,
        message: errors.map((v) => v.message).join('\n'),
        backdropDismiss: true,
        buttons: ['OK'],
      })
    ).pipe(concatMap((alert) => alert.present()));
  }

  async showError(message: string) {
    let errorFlag = false;
    const alert = await this.alertController.create({
      header: 'Information',
      message: message,
      backdropDismiss: true,
      buttons: ['OK'],
    });
    await alert.present();
    await alert.onDidDismiss().then((data) => {});

    return errorFlag;
  }

  async alertMessage(message: string) {
    const alert = await this.alertController.create({
      header: 'Information',
      message: message,
      backdropDismiss: false,
      buttons: [
        {
          text: 'Yes',
          handler: () => {
            alert.dismiss(true);
            return false;
          },
        },
        {
          text: 'No',
          handler: () => {
            alert.dismiss(false);
            return false;
          },
        },
      ],
    });
    await alert.present();

    return alert;
  }

  async confirmationMessage(message: string) {
    const alert = await this.alertController.create({
      header: 'Information',
      message: message,
      backdropDismiss: false,
      buttons: [
        {
          text: 'Confirm',
          handler: () => {
            alert.dismiss(true);
            return false;
          },
        },
        {
          text: 'Cancel',
          handler: () => {
            alert.dismiss(false);
            return false;
          },
        },
      ],
    });
    await alert.present();

    return alert;
  }

  getAsset(uri: string) {
    return this.httpClient.get<any>(uri, {
      responseType: 'blob' as 'json',
    });
  }

  // NOTE: Not used yet
  getUsers(): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}/users/getByProfile`
    );
  }
}
