import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { concatMap, switchMap, take, tap } from 'rxjs/operators';
import { AccountService } from '../_services/account.service';

@Injectable({
  providedIn: 'root',
})
export class HttpInterceptorService implements HttpInterceptor {
  tokenRefreshInProgress: boolean = false;
  tokenRefreshDoneStream: Subject<void> = new Subject();

  constructor(private accountService: AccountService) {}

  private setAuthorizationHeader(req: HttpRequest<any>): HttpRequest<any> {
    return req.clone({
      headers: req.headers.set(
        'Authorization',
        `Bearer ${this.accountService.accessToken}`
      ),
    });
  }

  private refreshTokens(req: HttpRequest<any>, next: HttpHandler) {
    return of(null).pipe(
      concatMap(() => {
        if (this.tokenRefreshInProgress) {
          // If the tokens are refreshing wait until this stream broadcasts complete, take first value because once completed we need to resume, no need to keep listening
          return this.tokenRefreshDoneStream.pipe(take(1));
        }

        // Otherwise set the flag and refresh the token

        this.tokenRefreshInProgress = true;

        return this.accountService.refreshTokens().pipe(
          tap(() => {
            // When done toggle back the flag and notify the others to proceed
            this.tokenRefreshDoneStream.next();
            this.tokenRefreshInProgress = false;
          })
        );
      }),
      // If reached here meaning token refreshed so just continue handling the original request as usual
      switchMap(() => next.handle(this.setAuthorizationHeader(req)))
    );
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.accountService.accessToken) {
      req = this.setAuthorizationHeader(req);
    }
    return next.handle(req).pipe(
      switchMap((event) => {
        if (event instanceof HttpResponse) {
          if (
            event.body instanceof Blob &&
            event.body.type === 'application/json'
          ) {
            // Refresh tokens for handling image types
            return this.refreshTokens(req, next);
          }
          if (!event.body.success && event.body.extras?.jwt) {
            // Refresh tokens for handling normal requests
            return this.refreshTokens(req, next);
          }
        }

        return of(event);
      })
    );
  }
}
